import "lazysizes"
import "picturefill"

$('.contentFaq__item__q').each(function() {
  $(this).on('click', function() {
    $('+.contentFaq__item__a', this).slideToggle();
    $(this).toggleClass('is-active');
    return false;
  });
});

$('.header__spBtn, .header__spMenu__item').on('click', function () {
  $('html').toggleClass('scroll-prevent')
  if ($(".header__spBtn").hasClass('is-active')) {
    $(".header__spBtn").removeClass('is-active');
    $('.header__spMenu').slideUp();
  } else {
    $(".header__spBtn").addClass('is-active');
    $('.header__spMenu').slideDown();
  }
})

$(function(){
  $('a[href^="#"]').click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position
    if ($(window).width() >= 769) {
      position = target.offset().top - 80;
    } else {
      position = target.offset().top - 60;
    }
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
});

$('.contentFee__flow__list').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  dots: true,
  dotsClass: 'slide-dots',
  prevArrow: `<img src="${path}/img/slick_left.svg" class="slide-arrow prev-arrow">`,
  nextArrow: `<img src="${path}/img/slick_right.svg" class="slide-arrow next-arrow">`,
});